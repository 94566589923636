import React from 'react';

const LoaderCircleIcon = () => {
    return (
        <svg width="141" height="141" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_276_601" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="8" y="9" width="127"
                  height="127">
                <circle cx="71.1742" cy="72.5244" r="62.9821" transform="rotate(180 71.1742 72.5244)" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_276_601)">
                <rect opacity="0.2" x="134.156" y="134.506" width="278" height="278"
                      transform="rotate(180 134.156 134.506)" fill="url(#paint0_radial_276_601)"/>
            </g>
            <path opacity="0.4"
                  d="M8.46805 69.1072L8.47485 69.0664V69.025C8.47485 34.3284 35.093 8.2429 69.6036 8.2429C86.9062 8.2429 101.733 12.8882 112.762 22.7807C123.785 32.6682 131.076 47.8518 133.207 69.05C133.193 103.731 104.11 131.857 69.6036 131.857C52.3432 131.857 35.5988 124.846 23.9013 113.489C12.2092 102.137 5.57516 86.4622 8.46805 69.1072Z"
                  stroke="white"/>
            <path opacity="0.4"
                  d="M139.746 69.4211L139.739 69.3808V69.34C139.739 30.0375 110.016 0.5 71.4993 0.5C52.1919 0.5 35.6404 5.75839 23.3254 16.9637C11.0149 28.1651 2.87777 45.3636 0.500005 69.3647C0.513583 108.652 32.9862 140.5 71.4993 140.5C110.081 140.5 146.204 108.726 139.746 69.4211Z"
                  stroke="white"/>
            <path
                d="M134.264 69.1524L134.25 69.0707V68.988C134.25 33.3091 106.905 6.49356 71.4281 6.49356C53.5951 6.49356 38.367 11.2825 27.0561 21.4328C15.756 31.5734 8.24526 47.1731 6.04397 69.0379C6.07185 104.686 35.9599 133.607 71.4281 133.607C89.1767 133.607 106.397 126.392 118.421 114.711C130.434 103.042 137.23 86.9541 134.264 69.1524Z"
                stroke="white" strokeWidth="2"/>
            <defs>
                <radialGradient id="paint0_radial_276_601" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(273.156 273.506) rotate(90) scale(171.5)">
                    <stop stopOpacity="0"/>
                    <stop offset="1"/>
                </radialGradient>
            </defs>
        </svg>

    );
};

export default LoaderCircleIcon;
