import React from 'react';

const MenuReferralsIcon = () => {
    return (
        <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3" y="5.14807" width="7.2807" height="7.2807" transform="rotate(-45 3 5.14807)" fill="white"/>
            <rect y="10.9999" width="16" height="11" fill="white"/>
        </svg>

    );
};

export default MenuReferralsIcon;
