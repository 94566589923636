import React from 'react';

const ArrowUpIcon = () => {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
                <path d="M0 7.5L7.75862 0.5L15 7.5H0Z" fill="white"/>
                <path d="M0 15.5L7.75862 8.5L15 15.5H0Z" fill="white"/>
            </g>
        </svg>
    );
};

export default ArrowUpIcon;
