import React, {useEffect, useState} from 'react';
import ScaleWrapper from "../components/Wrappers/ScaleWrapper";
import ScreenWrapper from "../components/Wrappers/ScreenWrapper";
import Header from "../components/CodesScreen/Header";
import Body from "../components/CodesScreen/Body";
import WebApp from "@twa-dev/sdk";
import Loader from "../components/Loader";
import {fetchCodeAttempts, fetchCodes} from "../store/codesSlice";
import {initNotification} from "../store/notificationSlice";
import {useAppDispatch, useAppSelector} from "../hooks/hooks";


const CodesScreen: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();
    const codesStatus = useAppSelector((state) => state.codes.status)
    const codesError = useAppSelector((state) => state.codes.error)
    const [headerStyle, setHeaderStyle] = useState({display: 'block'})
    const [bodyHeight, setBodyHeight] = useState('calc(100% - 232px)')

    useEffect(() => {
        WebApp.HapticFeedback.impactOccurred('soft');
    }, []);

    useEffect(() => {
        if(codesStatus === 'idle') {
            const fetchCodesAndAttemptsAsync = async () => {
                await dispatch(fetchCodes()).then(res => {
                    dispatch(fetchCodeAttempts());
                });
            }
            fetchCodesAndAttemptsAsync();
        }  else if(['fulfilled','fulfilled_max','fulfilled_incorrect','fulfilled_correct'].includes(codesStatus)) {
            setLoading(false)
        } else if(codesStatus === 'rejected') {
            dispatch(initNotification({data: {message: codesError, type:'error',show: true}}))
        }
    }, [dispatch, codesError, codesStatus]);

    const onFocusHandler = (focused: boolean) => {
        if(focused) {
            setHeaderStyle({display: 'none'})
            if(WebApp.platform === 'android') {
                setBodyHeight('100%')
            } else {
                setBodyHeight('100% - 200px');
            }
        } else {
            setHeaderStyle({display: 'block'})
            setBodyHeight('calc(100% - 232px)');
        }
    }

    return (
        <ScreenWrapper>
            <Loader loading={loading} duration={300}>
                <ScaleWrapper style={headerStyle} height={`232px`}>
                    <Header/>
                </ScaleWrapper>

                <ScaleWrapper height={bodyHeight}>
                    <Body onFocusHandler={onFocusHandler}/>
                </ScaleWrapper>
            </Loader>
        </ScreenWrapper>
    );
};

export default CodesScreen;
