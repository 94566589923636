import React, {useState} from 'react';
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {getActiveTasks, getClaimedTasks} from "../../store/tasksSlice";

const Header: React.FC = () => {
    const dispatch = useAppDispatch();
    const taskCount = useAppSelector(state => state.tasks.filteredData)
    const [active, setActive] = useState('current');

    const clickHandler = (section: string) => {
        setActive(section);
        if(section === 'log') {
            dispatch(getClaimedTasks())
        } else {
            dispatch(getActiveTasks())
        }
    }

    return (
        <Container>
            <Heading>
                <H1>Tasks</H1>
                <Span>{taskCount.length}</Span>
            </Heading>
            <Ul>
                <Li active={active === 'current'} onClick={() => clickHandler('current')}>Current</Li>
                <Li active={active === 'log'} onClick={() => clickHandler('log')}>Log</Li>
            </Ul>
        </Container>
    );
};

export default Header;

const Container = styled.div`
    padding: 24px 24px 0;
`;
const Heading = styled.div`
    display: flex;
    align-items: center;
    column-gap: 16px;
`;
const H1 = styled.h1`
    margin: 0;
    opacity: .7;
    font-size: 37px;
    font-weight: 700;
`;
const Span = styled.span`
    font-size: 21px;
    font-weight: 700;
    color: #fff;
`;
const Ul = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    column-gap: 40px;
`;
const Li = styled.li<{active?: boolean}>`
    font-size: 21px;
    padding-bottom: 10px;
    font-weight: ${({active}) => active ? '800' : '700'};
    opacity: ${({active}) => active ? '1' : '.7'};
    border-bottom: ${({active}) => active ? '2px solid #fff' : 'none'};
`;
