import React from 'react';

const ReferralIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
                <rect x="8.25" y="7.61194" width="5.46048" height="5.46048" transform="rotate(-45 8.25 7.61194)"
                      fill="white"/>
                <rect x="6" y="12" width="12" height="8" fill="white"/>
            </g>
        </svg>

    );
};

export default ReferralIcon;
