import React, {useEffect} from 'react';
import ScreenWrapper from "../components/Wrappers/ScreenWrapper";
import ScaleWrapper from "../components/Wrappers/ScaleWrapper";
import Body from "../components/ReferralScreen/Body";
import Header from "../components/ReferralScreen/Header";
import WebApp from "@twa-dev/sdk";

const ReferralScreen: React.FC = () => {

    useEffect(() => {
        WebApp.HapticFeedback.impactOccurred('soft');
    }, []);

    return (
        <ScreenWrapper>
            <ScaleWrapper height="262px">
                <Header/>
            </ScaleWrapper>

            <ScaleWrapper height={`100% - 262px`}>
                <Body/>
            </ScaleWrapper>
        </ScreenWrapper>
    );
};

export default ReferralScreen;
