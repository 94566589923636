import React from 'react';
import ReferralIcon from "../Images/ReferralIcon";
import ArrowUpIcon from "../Images/ArrowUpIcon";
import styled from "styled-components";
import {ReferralProps} from "../../store/referralsSlice";


const ReferralItem: React.FC<{ referral: ReferralProps }> = ({referral}) => {
    return (
        <Container>
            <Name>{truncateString(referral.telegram_username, 8)}</Name>
            <IconWrapper>
                <ReferralIcon/>
                <Text>W+{referral.referral_count}</Text>
            </IconWrapper>
            <IconWrapper>
                <ArrowUpIcon/>
                <Text>{referral.points.toLocaleString() || 0}</Text>
            </IconWrapper>
        </Container>
    );
};

export default ReferralItem;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    `;
const Name = styled.div`
    flex-basis: 40%;
    font-size: 18px;
    font-weight: 700;
    `;
const IconWrapper = styled.div`
    flex-basis: 30%;
    display: flex;
    align-items: center;
    column-gap: 8px;
    `;
const Text = styled.div`
    font-size: 18px;
    font-weight: 500;
    `;

const truncateString = (str:string, num:number) => {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}
