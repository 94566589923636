import {createSlice} from "@reduxjs/toolkit";
import {fetchCodes, sendCode} from "./codesSlice";

export interface SlideStateProps {
    day: number;
    season: number;
    reward: number;
    claimed: boolean;
    completed_at: string | null;
    guess: string;
}

const initialState: SlideStateProps = {
    day: 1,
    season: 1,
    reward: 0,
    claimed: false,
    completed_at: null,
    guess: ''
}

export const slideSlice = createSlice({
    name: 'slide',
    initialState,
    reducers: {
        setSlideState: (state, {payload}) => {
            state.day = payload.day;
            state.season = payload.season;
            state.reward = payload.reward;
            state.claimed = payload.claimed;
            state.completed_at = payload.completed_at;
        },
    },
});

export const { setSlideState } = slideSlice.actions
export default slideSlice.reducer
