import React, {useEffect} from 'react';
import Header from "../components/TaskScreen/Header";
import Body from "../components/TaskScreen/Body";
import ScaleWrapper from "../components/Wrappers/ScaleWrapper";
import ScreenWrapper from "../components/Wrappers/ScreenWrapper";
import WebApp from "@twa-dev/sdk";

const TaskScreen: React.FC = () => {

    useEffect(() => {
        WebApp.HapticFeedback.impactOccurred('soft');
    }, []);

    return (
        <ScreenWrapper>
            <ScaleWrapper height="154px">
                <Header/>
            </ScaleWrapper>
            <ScaleWrapper height={'100% - 154px'}>
                <Body/>
            </ScaleWrapper>
        </ScreenWrapper>
    );
};

export default TaskScreen;
