import React from 'react';
import SplashScreenIcon from "../Images/SplashScreenIcon";
import RotationAnimationWrapper from "../Wrappers/RotationAnimationWrapper";

const SplashScreenCircleButton = () => {


    return (
        <RotationAnimationWrapper duration={20}>
                <SplashScreenIcon/>
        </RotationAnimationWrapper>

)
    ;
};

export default SplashScreenCircleButton;
