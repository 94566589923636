import {createBrowserRouter} from "react-router-dom";
import Error404Screen from "../views/Error404Screen";
import RootWrapper from "../views/RootWrapper";
import TaskScreen from "../views/TaskScreen";
import ReferralScreen from "../views/ReferralScreen";
import CodesScreen from "../views/CodesScreen";
import MainScreen from "../views/MainScreen";
import Error401Screen from "../views/Error401Screen";

 const router = createBrowserRouter([

     {
         path: "/",
         element: <RootWrapper/>,
         errorElement: <Error404Screen />,
         children: [
             {
                 index: true,
                 element: <MainScreen />,
             },
             {
                 path: "main",
                 element: <MainScreen />,
             },
             {
                 path: "tasks",
                 element: <TaskScreen />,
             },
             {
                 path: "referral",
                 element: <ReferralScreen />,
             },
             {
                 path: "codes",
                 element: <CodesScreen />,
             },
         ],
     },
     {
         path: "/unauthorized",
         element: <Error401Screen/>,
         errorElement: <Error404Screen />,
     },
]);

 export default router;
