import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import notificationReducer from './notificationSlice'
import swipeReducer from './swipeSlice'
import tasksReducer from './tasksSlice'
import referralsReducer from './referralsSlice'
import codesReducer from './codesSlice'
import slideReducer from './slideSlice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        notification: notificationReducer,
        swipe: swipeReducer,
        tasks: tasksReducer,
        referrals: referralsReducer,
        codes: codesReducer,
        slide: slideReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
