import React, {useRef} from 'react';
import styled from "styled-components";
import LoaderCircleIcon from "./Images/LoaderCircleIcon";
import { Transition } from 'react-transition-group'
import RotationAnimationWrapper from "./Wrappers/RotationAnimationWrapper";
import SplashScreenCircleButton from "./SplashScreen/SplashScreenCircleButton";

interface LoaderProps {
    loading: boolean;
    duration?: number;
    children: React.ReactNode;
}

const Loader: React.FC<LoaderProps> = ({loading, duration, children}) => {
    const nodeRef = useRef(null);
    return (
        <>
            <Transition
                nodeRef={nodeRef}
                in={loading}
                timeout={duration ? duration : 500}
                unmountOnExit
            >
                {(state) => (
                    <Wrapper ref={nodeRef} state={state} duration={duration ? duration : 500}>
                        <RotationAnimationWrapper duration={5}>
                            {state !== 'exiting' && <LoaderCircleIcon/>}
                            {state === 'exiting' && <SplashScreenCircleButton/>}
                        </RotationAnimationWrapper>
                    </Wrapper>
                )}
            </Transition>
            {children}
        </>
    );
};

export default Loader;

const Wrapper = styled.div<{ state?: string; duration: number }>`
    position: fixed;
    z-index: 800;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity ${({duration}) => duration}ms, transform ${({duration}) => duration}ms;
    opacity: ${({ state }) => (state === "exiting" ? 0 : 1)};
    transform: scale(${({ state }) => (state === "exiting" && 2.1)});
`;
