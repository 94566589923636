import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {claimTask, getTasks, setTask} from "../utils/http";
import {AxiosError} from "axios";

export interface TaskProps {
    task_id: number | null;
    type: string;
    platform: string;
    link: string;
    description: string;
    progress_count: number;
    target_count: number;
    status: string;
    reward: number;
    enabled: boolean;
}
export interface TasksProps {
    data: TaskProps[];
    filteredData: TaskProps[]
    status: string;
    error: string | undefined
}

const initialState: TasksProps = {
    data: [],
    filteredData: [],
    status: 'idle', // idle | pending | fulfilled | rejected
    error: ''
}

export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async (_, {rejectWithValue}) => {
    try {
        const response = await getTasks();
        return response.data;
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const sendTask = createAsyncThunk('tasks/sendTask', async (taskData: TaskProps, {rejectWithValue}) => {
    try {
        const response = await setTask(taskData);
        return {...response.data, task_id: taskData.task_id};
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const fetchClaimTask = createAsyncThunk('tasks/fetchClaimTask', async (taskData: TaskProps, {rejectWithValue}) => {
    try {
        const response = await claimTask(taskData);
        return {...response.data, task_id: taskData.task_id};
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        getActiveTasks: (state) => {
            state.filteredData = state.data.filter(task => task.status !== 'claimed');
        },
        getClaimedTasks: (state) => {
            state.filteredData = state.data.filter(task => task.status === 'claimed');
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchTasks.pending, (state) => {
                state.status = 'pending';
                state.error = '';
            })
            .addCase(fetchTasks.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.data = action.payload.map((task:any) => {
                    const updatedTask = {...task}
                    if(task.type === 'invite') {
                        updatedTask.status = 'disabled'
                    } else {
                        updatedTask.status = 'not_started'
                    }
                    if(task.started_at) {
                        updatedTask.status = 'pending';
                    }
                    if(task.completed_at) {
                        updatedTask.status = 'finished';
                    }
                    if(task.claimed_at) {
                        updatedTask.status = 'claimed';
                    }
                    return updatedTask;
                });

            })
            .addCase(fetchTasks.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = 'Could not retrieve data from the server, try to reload the App';
            })
            .addCase(sendTask.fulfilled, (state, {payload}) => {
                state.data = state.data.map(task => {
                    const updatedTask = {...task};
                    if (task.task_id === payload.task_id) {
                        if(payload.started_at) {
                            updatedTask.status = 'pending';
                            return updatedTask;
                        }
                    }
                    return task;
                })
                state.filteredData = state.data.filter(task => task.status !== 'claimed');
            })
            .addCase(fetchClaimTask.fulfilled, (state, {payload}) => {
                state.data = state.data.map(task => {
                    const updatedTask = {...task};
                    if (task.task_id === payload.task_id) {
                        if(payload.claimed_at) {
                            updatedTask.status = 'claimed';
                            return updatedTask;
                        }
                    }
                    return task;
                })
                state.filteredData = state.data.filter(task => task.status !== 'claimed');
            })
    }
})

export const { getActiveTasks, getClaimedTasks } = tasksSlice.actions

export default tasksSlice.reducer
