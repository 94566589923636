import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {DiffToNow, PadZero} from "../../utils/date-utils";
import {fetchCodeAttempts} from "../../store/codesSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";

const InformationBlock: React.FC = () => {
    const slideState = useAppSelector((state) => state.slide)
    const codeStatus = useAppSelector((state) => state.codes.status)
    const codesData = useAppSelector((state) => state.codes)
    const slideData = useAppSelector((state) => state.slide)
    const dispatch = useAppDispatch();
    const [timeLeft, setTimeLeft] = useState(DiffToNow(codesData.next_attempt_time));

    useEffect(() => {
        if(codeStatus === 'fulfilled_max') {
            const timer = setInterval(() => {
                const left = DiffToNow(codesData.next_attempt_time);
                setTimeLeft(left);
                if(left.hours === 0 && left.minutes === 0 && left.seconds === 0) {
                    clearInterval(timer);
                    dispatch(fetchCodeAttempts());
                }
            }, 1000);
            return () => clearInterval(timer); // Clean up interval on component unmount
        }
    }, [dispatch, codeStatus]);


    return (
        <Container>
            {slideState.completed_at && slideState.claimed &&
                <Wrapper>
                    <P>Code is claimed!</P>
                    <P>You have claimed this code</P>
                </Wrapper>
            }
            {!slideState.completed_at && codeStatus === 'fulfilled_incorrect' &&
                <>
                    <P>Wrong code.</P>
                    <P>You have {codesData.remaining_attempts} attempts left</P>
                </>
            }
            {slideState.completed_at && !slideState.claimed &&
                <>
                    <P>Code is correct, and in time.</P>
                    <P>You`ve earned:</P>
                    <Points>+{slideData.reward}</Points>
                </>
            }
            {!slideState.completed_at && codeStatus === 'fulfilled_max' &&
                <Wrapper>
                    <P>Too many attempts, please wait</P>
                    {!isNaN(timeLeft.minutes) &&
                        <Timer>{`${PadZero(timeLeft.minutes)}:${PadZero(1 + timeLeft.seconds)}`}</Timer>
                    }
                </Wrapper>
            }

        </Container>
    );
};

export default InformationBlock;

const Container = styled.div`
    text-align: center;
    padding: 16px 24px;
`;
const P = styled.p`
    font-size: 15px;
    font-weight: 600;
    margin: 0;
`;
const Points = styled.p`
    font-weight: 700;
    font-size: 32px;
    margin: 0;
`;
const Wrapper = styled.div`
    margin: 40px 0 20px 0;
`;
const Timer = styled.h1`
    font-family: 'ZenDots', sans-serif;
    font-size: 48px;
    margin: 0;
    position: relative;
    cursor: pointer;
`;
