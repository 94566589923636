import React from 'react';

const SocialThreadsIcon = () => {
    return (
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6921 11.1235C16.5887 11.074 16.4837 11.0263 16.3774 10.9806C16.1921 7.56728 14.327 5.61312 11.1952 5.59312C11.181 5.59304 11.1669 5.59304 11.1528 5.59304C9.27955 5.59304 7.72164 6.39261 6.76275 7.84759L8.48512 9.0291C9.20145 7.94229 10.3257 7.7106 11.1536 7.7106C11.1631 7.7106 11.1727 7.7106 11.1822 7.71069C12.2134 7.71726 12.9915 8.01708 13.4951 8.60175C13.8616 9.02741 14.1067 9.61563 14.2281 10.358C13.3139 10.2026 12.3251 10.1548 11.2681 10.2154C8.29059 10.3869 6.37639 12.1235 6.50495 14.5365C6.57019 15.7605 7.17996 16.8135 8.22188 17.5014C9.1028 18.0829 10.2374 18.3673 11.4165 18.3029C12.9738 18.2175 14.1954 17.6234 15.0476 16.537C15.6949 15.712 16.1042 14.6429 16.285 13.2957C17.0271 13.7436 17.5771 14.333 17.8809 15.0415C18.3974 16.2459 18.4275 18.225 16.8126 19.8385C15.3978 21.252 13.697 21.8635 11.1267 21.8824C8.27552 21.8612 6.11922 20.9469 4.71726 19.1646C3.40444 17.4958 2.72596 15.0852 2.70065 12C2.72596 8.91473 3.40444 6.5042 4.71726 4.83534C6.11922 3.05311 8.27549 2.13875 11.1266 2.11756C13.9985 2.13891 16.1924 3.05767 17.648 4.8485C18.3618 5.7267 18.8999 6.8311 19.2546 8.11879L21.273 7.58028C20.843 5.99528 20.1664 4.62946 19.2456 3.49675C17.3795 1.20084 14.6503 0.0243935 11.1337 0H11.1196C7.6102 0.0243088 4.91151 1.20522 3.09854 3.50991C1.48524 5.5608 0.65305 8.41446 0.625087 11.9916L0.625 12L0.625087 12.0084C0.65305 15.5855 1.48524 18.4393 3.09854 20.4901C4.91151 22.7948 7.6102 23.9757 11.1196 24H11.1337C14.2538 23.9784 16.453 23.1615 18.2647 21.3514C20.6351 18.9832 20.5637 16.0149 19.7825 14.1926C19.222 12.8859 18.1534 11.8245 16.6921 11.1235ZM11.3051 16.1884C10.0001 16.2619 8.6443 15.6761 8.57745 14.4215C8.5279 13.4913 9.23948 12.4532 11.3851 12.3296C11.6309 12.3154 11.872 12.3085 12.1089 12.3085C12.8883 12.3085 13.6174 12.3842 14.2802 12.5291C14.033 15.6169 12.5828 16.1182 11.3051 16.1884Z"
                fill="white"/>
        </svg>
    );
};

export default SocialThreadsIcon;
