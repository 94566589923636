import React, {forwardRef} from 'react';
import MainBackground from "../Images/MainBackground";
import styled from "styled-components";

const MainAnimation = forwardRef<HTMLDivElement>((props,ref) => {
    return (
        <Container ref={ref}>
            <MainBackground/>
        </Container>
    );
});

export default MainAnimation;

const Container = styled.div`
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    top: -20px;
    z-index: 0;
    width: 100%;
`;
