import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getSwipeState, setSwipe} from "../utils/http";
import {AxiosError} from "axios";

export interface SwipeProps {
    data: {
        day: number;
        season: number;
        character: string;
        character_index: number;
        can_swipe: boolean;
        time_to_next_hint: string;
        characters: [];
    }
    status: string;
    error: string | undefined;
}

const initialState: SwipeProps = {
    data: {
        day: 1,
        season: 1,
        character: '',
        character_index: 0,
        can_swipe: false,
        time_to_next_hint: '',
        characters: [],
    },
    status: 'idle', // idle | pending | fulfilled | rejected
    error: ''
}

export const fetchSwipeState = createAsyncThunk('swipe/fetchSwipeState', async (_, {rejectWithValue}) => {
    try {
        const response = await getSwipeState();
        return response.data;
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const sendSwipeState = createAsyncThunk('swipe/sendSwipeState', async (_, {rejectWithValue}) => {
    try {
        const response = await setSwipe();
        return response.data;
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const swipeSlice = createSlice({
    name: 'swipe',
    initialState,
    reducers: {
        setCanSwipe: (state, {payload}) => {
            state.data.can_swipe = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSwipeState.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchSwipeState.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.data.day = action.payload.day;
                state.data.season = action.payload.season;
                state.data.character = action.payload.current_hint;
                state.data.character_index = action.payload.current_hint_index;
                state.data.can_swipe = !action.payload.swiped;
                state.data.time_to_next_hint = action.payload.next_hint_time;
            })
            .addCase(fetchSwipeState.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = 'Could not retrieve data from the server, try to reload the App';
            })
            .addCase(sendSwipeState.fulfilled, (state, action) => {
                state.status = 'fulfilled_send'
            })

        ;
    }
})

export const { setCanSwipe } = swipeSlice.actions
export default swipeSlice.reducer
