import React from 'react';
import styled, {keyframes} from "styled-components";

const PendingCircleIcon = () => {
    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9" stroke="#D9D9D9" strokeOpacity="0.3" strokeWidth="2"/>
            <path
                d="M10.0811 1C15.0069 1 19 5.02944 19 10C19 14.9706 15.0069 19 10.0811 19C7.73096 19 5.59314 18.0828 4 16.5837"
                stroke="white" strokeWidth="2" strokeLinecap="round"/>
            {/*<animateTransform attributeType="xml" attributeName="transform" type="rotate" from="360"*/}
            {/*                  to="0" dur="1.5s" additive="sum" repeatCount="indefinite"/>*/}
        </Svg>

    );
};

export default PendingCircleIcon;

const CircleRotation = keyframes`
    0% {transform: rotate(0deg); }
    100% {transform: rotate(360deg);}
`;

const Svg = styled.svg`
    animation-name: ${CircleRotation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`;
