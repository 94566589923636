import React from 'react';
import ReferralIcon from "../Images/ReferralIcon";
import styled from "styled-components";
import RectangleIcon from "../Images/RectangleIcon";

interface ReferralCardProps {
    children: React.ReactNode;
    userCount: number;
}

const ReferralCard: React.FC<ReferralCardProps> = ({userCount, children}) => {
    return (
        <Container>
            <div style={{minWidth: '25%'}}>
                <RefPill>
                    <ReferralIcon/>
                    <span>{userCount}</span>
                </RefPill>
            </div>
            <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', left: -22}}><RectangleIcon/></div>
                {children}
            </div>
        </Container>
    );
};

export default ReferralCard;

const Container = styled.div`
    display: flex;
    margin: 12px 0;
    column-gap: 30px;
    align-items: flex-start;
    justify-content: space-between;
`;
const RefPill = styled.div`
    max-height: 24px;
    display: inline-flex;
    column-gap: 2px;
    align-items: center;
    padding: 2px 8px;
    background-color: rgba(255,255,255,.2);
    border-radius: 80px;
    font-weight: 700;
    font-size: 14px;
`;
