import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {getStrike, getToken, getUser} from "../utils/http";
import {AxiosError} from "axios";
import {sendSwipeState} from "./swipeSlice";
import {fetchClaimTask, sendTask} from "./tasksSlice";
import {getClaim, sendCode} from "./codesSlice";
import router from "../router/router";
import {fetchClaimReferrals} from "./referralsSlice";

export interface UserStateProps {
    data: {id: number | null;
        telegram_username: string;
        telegram_id: number | null;
        invitation_code: string;
        strikes: number;
        points: number;
        referral_count: number;
        subreferral_count: number;
        jwt_token: string | null;
        refresh_token: string | null;
        current_strike_length?: number;
        points_awarded?: number;
        season: number;
        day: number;
    };
    status: string;
    error: string | undefined
}

const initialState: UserStateProps = {
    data: {
        id: null,
        telegram_username: '',
        telegram_id: null,
        invitation_code: '',
        strikes: 0,
        points: 0,
        referral_count: 0,
        subreferral_count: 0,
        jwt_token: null,
        refresh_token: null,
        current_strike_length: 0,
        points_awarded: 0,
        season: 1,
        day: 1,
    },
    status: 'idle', // idle | pending | fulfilled | rejected
    error: ''
}

export const fetchUser = createAsyncThunk('user/fetchUser', async (telegramData: {data:string, invitation_code:string}, {rejectWithValue}) => {
    try {
        const response = await getUser(telegramData);
        return response.data;
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const fetchStrike = createAsyncThunk('user/fetchStrike', async (_, {rejectWithValue}) => {
    try {
        const response = await getStrike();
        return response.data;
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.status = 'pending';
                state.error = '';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.data = action.payload;
                state.data.points = action.payload.points ? action.payload.points : 0;
                if(action.payload.jwt_token) {
                    localStorage.setItem('token', action.payload.jwt_token);
                }
                if(action.payload.refresh_token) {
                    localStorage.setItem('refresh_token', action.payload.refresh_token);
                }
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = 'Could not retrieve data from the server, try to reload the App';
            })
            .addCase(sendSwipeState.fulfilled, (state, action) => {
                if(!isNaN(action.payload.reward)) {
                    state.data.points += +action.payload.reward
                }
            })
            .addCase(sendTask.fulfilled, (state, action) => {
                if(action.payload.status === 'claimed') {
                    state.data.points += +action.payload.reward
                }
            })
            .addCase(fetchStrike.pending, (state) => {
                state.status = 'pending_strike';
            })
            .addCase(fetchStrike.fulfilled, (state, action) => {
                state.status = 'fulfilled_strike';
                state.data.current_strike_length = action.payload.strike_length || 1;
                state.data.points_awarded = action.payload.reward ? action.payload.reward : 0;
                if(!isNaN(action.payload.reward)) {
                    state.data.points += +action.payload.reward
                }
            })
            .addCase(fetchStrike.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = 'Could not retrieve data from the server, try to reload the App';
            })
            .addCase(getClaim.fulfilled, (state, action) => {
                state.data.points = action.payload.points;
            })
            .addCase(fetchClaimTask.fulfilled, (state, action) => {
                state.data.points = action.payload.total_points;
            })
            .addCase(fetchClaimReferrals.fulfilled, (state, action) => {
                state.data.points = action.payload.new_total_points;
            });
    }
})

// Action creators are generated for each case reducer function
export const { } = userSlice.actions
export default userSlice.reducer
