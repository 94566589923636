import React from 'react';
import ActionButton from "../Buttons/ActionButton";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import WebApp from "@twa-dev/sdk";
import {initNotification} from "../../store/notificationSlice";

interface InviteModalProps {
    clickHandler: (event: React.MouseEvent<HTMLDivElement>) => void
}

const InviteModal: React.FC<InviteModalProps> = ({clickHandler}) => {
    const dispatch = useAppDispatch();
    const inviteCode = useAppSelector(state => state.user.data.invitation_code)
    const bot_name = process.env.REACT_APP_BOT_NAME;
    const shareText = 'Discover secret Mystery codes and earn points for crypto listing';

    const inviteLink = `https://t.me/${bot_name}?startapp=${inviteCode}`;
    const fullUrl = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(shareText)}`;

    const copyHandler = () => {
        navigator.clipboard.writeText(inviteLink);
        dispatch(initNotification({data: {message: 'Referral link copied', type: 'info', show: true}}))
    }

    const sendHandler = () => {
        WebApp.openTelegramLink(fullUrl);
    }


    return (
        <Wrapper onClick={clickHandler}>
            <Container onClick={e => e.stopPropagation()}>
                <H2>invite witnesses</H2>
                <ActionButton type="active" width="100%" height="48px" clickHandler={sendHandler}>send</ActionButton>
                <ActionButton type="active" width="100%" height="48px" clickHandler={copyHandler}>copy link</ActionButton>
                <CloseButton onClick={clickHandler}>close</CloseButton>
            </Container>
        </Wrapper>
    );
};

export default InviteModal;

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0,.7);
    display: flex;
    align-items: end;
`;
const Container = styled.div`
    padding: 32px 16px 16px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 8px 8px;
    color: #000;
    text-align: center;
    row-gap: 16px;
`;
const H2 = styled.h2`
    font-size: 25px;
    font-weight: 700;
    margin: 0 0 16px;
`;
const CloseButton = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
