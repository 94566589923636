import React from 'react';

const MenuMainIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10.5" stroke="white"/>
            <circle cx="11" cy="11" r="6" stroke="white" strokeWidth="2"/>
        </svg>
    );
};

export default MenuMainIcon;
