import React from 'react';
import InformationBlock from "./InformationBlock";
import FormBlock from "./FormBlock";

const CodeBlock:React.FC<{onFocusHandler: (focused: boolean) => void}> = ({onFocusHandler}) => {

    return (
        <>
            <InformationBlock/>
            <FormBlock onFocusHandler={onFocusHandler}/>
        </>
    );
};

export default CodeBlock;

