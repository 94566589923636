import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import SliderItem from "./SliderItem";
import styled from "styled-components";

import {useAppSelector} from "../../hooks/hooks";


const Slider: React.FC = () => {
    const codesData = useAppSelector((state) => state.codes.data)

    return (
        <div>
            {codesData.length &&
                <Swiper
                    initialSlide={codesData.length - 1}
                    spaceBetween={8}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                >
                    {codesData && codesData.map((code, index) => <StyledSwiperSlide key={index}><SliderItem code={code}/></StyledSwiperSlide>)}
                </Swiper>
            }
        </div>
    );
};

export default Slider;

const StyledSwiperSlide = styled(SwiperSlide)`
    width: 90%;
    padding: 18px 0 0;
`;
