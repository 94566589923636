import React from 'react';
import SocialXIcon from "../../Images/Socials/SocialXIcon";
import SocialYouTubeIcon from "../../Images/Socials/SocialYouTubeIcon";
import ReferralIcon from "../../Images/ReferralIcon";
import SocialFacebookIcon from "../../Images/Socials/SocialFacebookIcon";
import SocialTwitchIcon from "../../Images/Socials/SocialTwitchIcon";
import SocialInstagramIcon from "../../Images/Socials/SocialInstagramIcon";
import SocialTelegramIcon from "../../Images/Socials/SocialTelegramIcon";
import SocialTiktokIcon from "../../Images/Socials/SocialTiktokIcon";
import SocialThreadsIcon from "../../Images/Socials/SocialThreadsIcon";

const TaskResource: React.FC<{resource:string}> = ({resource}) => {

    switch (resource.toLowerCase()) {
        case 'youtube':
            return <SocialYouTubeIcon/>
        case 'twitter':
            return <SocialXIcon/>
        case 'facebook':
            return <SocialFacebookIcon/>
        case 'twitch':
            return <SocialTwitchIcon/>
        case 'instagram':
            return <SocialInstagramIcon/>
        case 'telegram':
            return <SocialTelegramIcon/>
        case 'tiktok':
            return <SocialTiktokIcon/>
        case 'threads':
            return <SocialThreadsIcon/>
        default:
            return <ReferralIcon/>
    }
};

export default TaskResource;
