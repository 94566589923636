import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import TaskItem from "./TaskItem";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {initNotification} from "../../store/notificationSlice";
import {fetchTasks, getActiveTasks} from "../../store/tasksSlice";
import Loader from "../Loader";

const Body: React.FC = () => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const tasksData = useAppSelector((state) => state.tasks.filteredData)
    const tasksStatus = useAppSelector((state) => state.tasks.status)
    const tasksError = useAppSelector((state) => state.tasks.error)

    useEffect(() => {
        if(tasksStatus === 'idle') {
            dispatch(fetchTasks());
        } else if(tasksStatus === 'fulfilled') {
            dispatch(getActiveTasks())
            setLoading(false)
        } else if(tasksStatus === 'rejected') {
            dispatch(initNotification({data: {message: tasksError, type:'error',show: true}}))
        }
    }, [tasksStatus]);

    return (
        <Container>
            <Loader loading={loading} duration={300}>
                <Ul>
                    {tasksData && tasksData.map(task => <li key={task.task_id}><TaskItem task={task}/></li>)}
                </Ul>
            </Loader>
        </Container>
    );
};

export default Body;

const Container = styled.div`
    height: 100%;
    overflow-y: scroll;
`;
const Ul = styled.ul`
    list-style: none;
    padding: 0 24px;
    margin: 0;
`;
