import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {claimCode, getCodeAttempts, getCodes, setCode} from "../utils/http";
import {AxiosError} from "axios";
import {SlideStateProps} from "./slideSlice";
import {sendSwipeState} from "./swipeSlice";

export interface CodeProps {
    id: number;
    day: number;
    season: number;
    completed_season: number;
    completed_day: number;
    claimed:boolean;
    reward: number;
    swiped_characters: string;
    completed_at: string | null;
    swipes: {
        day: number;
        season: number;
        character: string;
        swiped_at: string;
        character_index: number;
    }[];
}
export interface CodesProps {
    data: CodeProps[];
    reward: number;
    claimed_day?: number;
    claimed_season?: number;
    remaining_attempts: number;
    next_attempt_time: string;
    status: string;
    error: string | undefined
}

export interface sendCodeProps {
    day: number;
    season: number;
    guess?: string;
}

const initialState: CodesProps = {
    data: [],
    reward: 0,
    remaining_attempts: 0,
    next_attempt_time: '',
    status: 'idle', // idle | pending | fulfilled | rejected
    error: ''
}

export const fetchCodes = createAsyncThunk('codes/fetchCodes', async (_, {rejectWithValue}) => {
    try {
        const response = await getCodes();
        return response.data;
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const fetchCodeAttempts = createAsyncThunk('codes/fetchCodeAttempts', async (_, {rejectWithValue}) => {
    try {
        const response = await getCodeAttempts();
        return response.data;
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const sendCode = createAsyncThunk('tasks/sendCode', async (codeData: sendCodeProps, {rejectWithValue, getState}) => {
    try {
        const { slide } = getState() as { slide: SlideStateProps}
        const response = await setCode(codeData);
        return {...response.data, claimed_day: slide.day, claimed_season: slide.season};
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const getClaim = createAsyncThunk('tasks/getClaim', async (codeData: sendCodeProps, {rejectWithValue}) => {
    try {
        const response = await claimCode(codeData);
        return response.data;
    } catch (_err) {
        const error = _err as AxiosError;
        return rejectWithValue({ data: error.message  });
    }
})

export const codesSlice = createSlice({
    name: 'codes',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCodes.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchCodes.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.data = action.payload;
            })
            .addCase(fetchCodes.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = 'Could not retrieve data from the server, try to reload the App';
            })
            .addCase(sendCode.pending, (state,{payload}) => {
                state.status = 'pending';
            })
            .addCase(sendCode.fulfilled, (state, {payload}) => {
                state.next_attempt_time = payload.next_attempt_time;
                if(payload.remaining_attempts) {
                    state.status = 'fulfilled_incorrect';
                    state.remaining_attempts = payload.remaining_attempts
                } else if(payload.reward) {
                    state.status = 'fulfilled_correct';
                    state.reward = payload.reward;
                    state.data = state.data.map(code => {
                        if(code.season === payload.claimed_season && code.day === payload.claimed_day) {
                            const updatedCode = {...code};
                            updatedCode.completed_at = new Date().toISOString();
                            updatedCode.reward = payload.reward
                            return updatedCode;
                        }
                        return code;
                    })
                    state.claimed_day = payload.claimed_day;
                    state.claimed_season = payload.claimed_season;
                } else {
                    state.status = 'fulfilled_max';
                    state.remaining_attempts = 0;
                }
            })
            .addCase(sendCode.rejected, (state, {payload}) => {
                state.status = 'rejected';
                state.error = 'Could not retrieve data from the server, try to reload the App';
            })

            .addCase(fetchCodeAttempts.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(fetchCodeAttempts.fulfilled, (state, action) => {
                state.next_attempt_time = action.payload.next_attempt_time;
                if(action.payload.remaining_attempts) {
                    state.status = 'fulfilled';
                    state.remaining_attempts = action.payload.remaining_attempts;
                } else {
                    state.status = 'fulfilled_max';
                    state.next_attempt_time = action.payload.next_attempt_time;
                }
            })
            .addCase(fetchCodeAttempts.rejected, (state, action) => {
                state.status = 'rejected';
                state.error = 'Could not retrieve remaining attempt data from the server, try to reload the App';
            })
            .addCase(getClaim.fulfilled, (state, {payload}) => {
                state.status = 'idle';
            })
            .addCase(sendSwipeState.fulfilled, (state, action) => {
                state.status = 'idle'
            })
    }
})

export const { } = codesSlice.actions

export default codesSlice.reducer
