import React from 'react';

const SortArrowDown = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 13.0875L5 8.08752L6.175 6.91252L10 10.7292L13.825 6.91252L15 8.08752L10 13.0875Z"
                  fill="white"/>
        </svg>
    );
};

export default SortArrowDown;
