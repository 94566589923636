import React from 'react';

const MenuTasksIcon = () => {
    return (
        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.6427 0.0223389L21.9999 2.37955L8.15061 16.2288L8.14636 16.2246L7.94242 16.4285L0.211914 8.698L2.56913 6.34079L7.94667 11.7183L19.6427 0.0223389Z"
                  fill="white"/>
        </svg>

    );
};

export default MenuTasksIcon;
