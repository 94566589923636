import React from 'react';

const CopyIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22 0H6V6H2H0V8V20V22H2H15H17V20V17H22V0ZM6 8V17H15V20H2V8H6Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22 0H6V6H2H0V8V20V22H2H15H17V20V17H22V0ZM6 8V17H15V20H2V8H6Z" fill="white"/>
        </svg>

    );
};

export default CopyIcon;
