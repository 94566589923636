import React from 'react';
import styled, {keyframes} from "styled-components";

interface AnimationProps {
    duration: number;
    children: React.ReactNode;
}
const RotationAnimationWrapper: React.FC<AnimationProps> = ({duration, children}) => {
    return (
        <Animation duration={duration}>
            {children}
        </Animation>
    );
};

export default RotationAnimationWrapper;

const CircleRotation = keyframes`
    0% {transform: rotate(0deg); }
    25% {transform: rotate(90deg) scale(1.1,1.1);}
    50% {transform: rotate(180deg) scale(1,1)}
    75% {transform: rotate(270deg) scale(1.1,1.1)}
    100% {transform: rotate(360deg) scale(1,1);}
    `;

const Animation = styled.div<{ duration: number }>`
    animation-name: ${CircleRotation};
    animation-duration: ${({duration}) => duration}s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`;
