import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
    futureTimestamp: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ futureTimestamp }) => {
    const [timeLeft, setTimeLeft] = useState(futureTimestamp - Date.now());

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                const remaining = futureTimestamp - Date.now();
                if (remaining <= 0) {
                    clearInterval(timer);
                    setTimeLeft(0);
                } else {
                    setTimeLeft(remaining);
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [futureTimestamp, timeLeft]);

    const formatTime = (time: number) => {
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const seconds = Math.floor((time / 1000) % 60);
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    return (
        <span>{formatTime(timeLeft)}</span>
    );
};

export default CountdownTimer;
