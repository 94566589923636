import React from 'react';

const SocialYouTubeIcon = () => {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M22.7462 1.83407C23.1185 2.20883 23.3859 2.67482 23.5216 3.18541C24.0234 5.06995 24.0234 8.99996 24.0234 8.99996C24.0234 8.99996 24.0234 12.93 23.5216 14.8145C23.3859 15.3251 23.1185 15.7911 22.7462 16.1658C22.3738 16.5406 21.9095 16.811 21.3998 16.95C19.5234 17.4545 12.0234 17.4545 12.0234 17.4545C12.0234 17.4545 4.52344 17.4545 2.64707 16.95C2.13737 16.811 1.6731 16.5406 1.30073 16.1658C0.928354 15.7911 0.660943 15.3251 0.525256 14.8145C0.0234376 12.93 0.0234375 8.99996 0.0234375 8.99996C0.0234375 8.99996 0.0234376 5.06995 0.525256 3.18541C0.660943 2.67482 0.928354 2.20883 1.30073 1.83407C1.6731 1.4593 2.13737 1.18891 2.64707 1.04996C4.52344 0.54541 12.0234 0.54541 12.0234 0.54541C12.0234 0.54541 19.5234 0.54541 21.3998 1.04996C21.9095 1.18891 22.3738 1.4593 22.7462 1.83407ZM15.8416 8.99999L9.56891 5.43135V12.5686L15.8416 8.99999Z"
                  fill="white"/>
        </svg>
    );
};

export default SocialYouTubeIcon;
