import React from 'react';
import {Link} from "react-router-dom";

const Error401Screen: React.FC = () => {
    return (
        <div style={{width:'100%', height:'100%', display:'flex', flexDirection: "column", justifyContent:'center', alignItems: 'center'}}>
            <h2>Authorization error, 401</h2>
            <Link to="/" reloadDocument={true}>Reload App</Link>
        </div>
    );
};

export default Error401Screen;
