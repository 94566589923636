import React from 'react';

const CheckIcon = () => {
    return (
        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.5334 0.0893555L19.584 2.13997L7.53608 14.1879L7.53226 14.184L7.35488 14.3614L0.629883 7.63642L2.68049 5.58581L7.3587 10.264L17.5334 0.0893555Z"
                  fill="currentColor"/>
        </svg>

    );
};

export default CheckIcon;
