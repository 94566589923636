import React from 'react';
import {Link} from "react-router-dom";

const Error404Screen = () => {
    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <h2>Page not found, 404</h2>
            <Link to="main">Go to Main Page</Link>
        </div>
    );
};

export default Error404Screen;
