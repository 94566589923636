import React from 'react';

const SocialTwitchIcon = () => {
    return (
        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0006 4.71429H15.2863V9.85715H17.0006V4.71429Z" fill="white"/>
            <path d="M10.5719 4.71429H12.2862V9.85715H10.5719V4.71429Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.00056 0L0.714844 4.28571V19.7143H5.8577V24L10.1434 19.7143H13.572L21.2863 12V0H5.00056ZM19.572 11.1429L16.1434 14.5714H12.7148L9.71484 17.5714V14.5714H5.8577V1.71429H19.572V11.1429Z"
                  fill="white"/>
        </svg>
    );
};

export default SocialTwitchIcon;
