import React from 'react';

const InputCodeArrowIcon = () => {
    return (
        <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 16L9.5 8.5L1 1" stroke="white"/>
        </svg>
    );
};

export default InputCodeArrowIcon;
