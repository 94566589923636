import React from 'react';
import styled from "styled-components";

interface BodyWrapperProps {
    children: React.ReactNode;
    height: string;
    style?: {}
}
const ScaleWrapper: React.FC<BodyWrapperProps> = ({children, height, style}) => {
    return (
        <Container style={style} height={height}>
            {children}
        </Container>
    );
};

export default ScaleWrapper;

const Container = styled.div<{height:string}>`
    height: calc(${({height}) => height});
`;
