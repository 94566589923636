import axios, {AxiosResponse, InternalAxiosRequestConfig} from "axios";
import router from "../router/router";
import {getToken} from "./http";

const getBaseUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            return process.env.REACT_APP_BASE_URL_LOC;
        case 'development':
            return process.env.REACT_APP_BASE_URL_DEV;
        case 'production':
            return process.env.REACT_APP_BASE_URL;
        default:
            return process.env.REACT_APP_BASE_URL;
    }
}
export const BASE_URL = getBaseUrl();

// ### AXIOS Base config ###
const axiosClient = axios.create({
    baseURL: `${BASE_URL}/api`,
})

// ### AXIOS Request token injection ###
axiosClient.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if(localStorage.getItem('token') && config.url !== 'players/register/') {
        const token = localStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})

// ### AXIOS Unauthorized response prefetch ###
axiosClient.interceptors.response.use((response: AxiosResponse) => {
    return response;
}, error => {
    if(error.response.status === 401 && error.response.data.code === 'token_not_valid' && localStorage.getItem('refresh_token')) {
        getToken(localStorage.getItem('refresh_token')).then(res => {
            if(res.data.access && res.data.refresh) {
                localStorage.setItem('token', res.data.access);
                localStorage.setItem('refresh_token', res.data.refresh);
                window.location.replace('/');
            } else {
                router.navigate('/unauthorized');
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                return error;
            }
        }).catch(error => {
            router.navigate('/unauthorized');
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            return error;
        })
    } else if(error.response && error.response.status === 401) {
        router.navigate('/unauthorized');
        localStorage.removeItem('token');
        return error;
    }
    throw error;
})

export default  axiosClient;
