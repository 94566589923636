import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import MainAnimation from "../components/MainScreen/MainAnimation";
import SwipeBlock from "../components/MainScreen/SwipeBlock";
import ScoreBlock from "../components/MainScreen/ScoreBlock";
import ScreenWrapper from "../components/Wrappers/ScreenWrapper";
import {useAppSelector} from "../hooks/hooks";
import Loader from "../components/Loader";
import WebApp from "@twa-dev/sdk";

const MainScreen: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const swipeStateStatus = useAppSelector((state) => state.swipe.status)
    const swipeStateData = useAppSelector((state) => state.swipe.data)
    const userData = useAppSelector((state) => state.user.data)

    useEffect(() => {
        WebApp.HapticFeedback.impactOccurred('soft');
    }, []);

    useEffect(() => {
        if (swipeStateStatus === 'fulfilled' || swipeStateStatus === 'fulfilled_send') {
            setLoading(false);
        }
    }, [swipeStateStatus]);

    const backgroundRef = React.useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if(backgroundRef.current) {
            setHeight(backgroundRef.current.clientHeight / 2)
        }
    }, []);

    return (
        <ScreenWrapper>
            <Loader loading={loading} duration={300}>
            <MainAnimation ref={backgroundRef} />
            <BodyWrapper>
                <Body>
                    <SwipeBlock animationHeight={height}/>
                    <ScoreBlock points={userData.points} day={swipeStateData.day} season={swipeStateData.season}/>
                </Body>
            </BodyWrapper>
            </Loader>
        </ScreenWrapper>
    );
};

export default MainScreen;


const BodyWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    text-align: center;
`;
const Body = styled.div`
    width: 100%;
    
`;
