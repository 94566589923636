import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {sendSwipeState} from "./swipeSlice";
import {fetchClaimTask, sendTask} from "./tasksSlice";
import {getClaim} from "./codesSlice";
import {fetchClaimReferrals} from "./referralsSlice";

export interface PopupStateProps {
    data: {message: string | undefined;
        type: string;
        show: boolean;
    }
}

const initialState: PopupStateProps = {
    data: {
        message: '',
        type: '',
        show: false
    }
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        initNotification: (state, action: PayloadAction<PopupStateProps>) => {
            state.data = action.payload.data
        },
    },
    extraReducers: builder => {
        builder
            .addCase(sendSwipeState.fulfilled, (state, action) => {
                if(!isNaN(action.payload.reward)) {
                    state.data = {message: `You’ve earned:<br> <b style="font-size: 25px">+${action.payload.reward}</b>`, type: 'info', show: true}
                }
            })
            .addCase(sendTask.fulfilled, (state, action) => {
                if(action.payload.status === 'claimed') {
                    state.data = {message: `You’ve earned:<br> <b style="font-size: 25px">+${action.payload.reward}</b>`, type: 'info', show: true}
                }
            })
            .addCase(getClaim.fulfilled, (state, action) => {
                state.data = {message: `You’ve earned:<br> <b style="font-size: 25px">+${action.payload.claimed_reward}</b>`, type: 'info', show: true}
            })
            .addCase(fetchClaimTask.fulfilled, (state, action) => {
                state.data = {message: `You’ve earned:<br> <b style="font-size: 25px">+${action.payload.reward}</b>`, type: 'info', show: true}
            })
            .addCase(fetchClaimReferrals.fulfilled, (state, action) => {
                state.data = {message: `You’ve earned:<br> <b style="font-size: 25px">+${action.payload.total_points_to_claim}</b>`, type: 'info', show: true}
            });
    }
})

// Action creators are generated for each case reducer function
export const { initNotification } = notificationSlice.actions
export default notificationSlice.reducer
