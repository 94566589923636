import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../hooks/hooks";
import {initNotification} from "../store/notificationSlice";
import styled from "styled-components";
import router from "../router/router";

const NotificationModal: React.FC = () => {
    const notificationData = useAppSelector((state) => state.notification.data)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(notificationData.show) {
            const timer = setTimeout(() => {
                dispatch(initNotification({data: {message: '', type: '', show: false}}))
                // if(notificationData.type === 'error') {
                //     router.navigate('/');
                // }
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [notificationData, dispatch]);


    return (
        <>
            {
                notificationData.show &&
                <Container type={notificationData.type}>
                    {notificationData.message && <div dangerouslySetInnerHTML={{__html: notificationData.message}}/>}
                </Container>
            }
        </>
    );
};

export default NotificationModal;

const Container = styled.div<{type: string}>`
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 64px);
    padding: 16px 24px;
    z-index: 999;
    font-size: 15px;
    font-weight: 700;
    background-color: ${({type}) => type === 'error' ? '#B42424' : '#EEEEEE'};
    color: ${({type}) => type === 'error' ? '#fff' : '#000'};
    `;
