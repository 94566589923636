import React from 'react';

const MenuCodesIcon = () => {
    return (
        <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 15.4895V9.00982H11.6163C13.1816 9.00982 13.9642 8.17672 13.9642 6.51052C13.9642 4.84432 13.1816 4.01122 11.6163 4.01122H0V0H12.2341C14.3762 0 16.0341 0.596541 17.2081 1.78962C18.4027 2.96213 19 4.52548 19 6.47966C19 8.43385 18.4027 10.0178 17.2081 11.2314C16.0341 12.4245 14.3762 13.021 12.2341 13.021H4.94309V15.4895H0ZM0 22V17.5877H4.94309V22H0Z"
                fill="white"/>
        </svg>
    );
};

export default MenuCodesIcon;
