import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {DiffToNow, PadZero} from "../../utils/date-utils";
import {fetchSwipeState} from "../../store/swipeSlice";
import SwipeElement from "./SwipeElement";

interface SwipeBlockProps {
    animationHeight: number
}

const SwipeBlock: React.FC<SwipeBlockProps> = ({animationHeight}) => {
    const swipeStateData = useAppSelector((state) => state.swipe.data)
    const dispatch = useAppDispatch();
    const [timeLeft, setTimeLeft] = useState(DiffToNow(swipeStateData.time_to_next_hint));

    useEffect(() => {
        const timer = setInterval(() => {
            const left = DiffToNow(swipeStateData.time_to_next_hint);
            setTimeLeft(left);
            if(left.hours === 0 && left.minutes === 0 && left.seconds === 0) {
                clearInterval(timer);
                dispatch(fetchSwipeState());
            }
        }, 1000);
        return () => clearInterval(timer); // Clean up interval on component unmount
    }, [dispatch, swipeStateData.time_to_next_hint]);

    return (
        <Container>
            {swipeStateData.can_swipe ?
                <SwipeElement animationHeight={animationHeight} character={swipeStateData.character} />
                :
                <>
                    {!isNaN(timeLeft.hours) && <Timer>{`${PadZero(timeLeft.hours)}:${PadZero(timeLeft.minutes)}:${PadZero(1 + timeLeft.seconds)}`}</Timer>}
                </>
            }
        </Container>
    );
};

export default SwipeBlock;

const Container = styled.div`
    z-index: 10;
`;
const Timer = styled.h1`
    font-family: 'ZenDots', sans-serif;
    font-size: 44px;
    font-weight: 400;
    margin: 90px 0;
    position: relative;
    cursor: pointer;
`;
