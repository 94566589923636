import React, {useEffect, useRef, useState} from 'react';

const letterCount = 30;
const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Ъ', 'Ы', 'Ь', 'Э', 'Ю', 'Я', 'ア', 'イ', 'ウ', 'エ', 'オ', 'カ', 'キ', 'ク', 'ケ', 'コ', 'サ', 'シ', 'ス', 'セ', 'ソ', 'タ', 'チ', 'ツ', 'テ', 'ト', 'ナ', 'ニ', 'ヌ', 'ネ', 'ノ', 'ハ', 'ヒ', 'フ', 'ヘ', 'ホ', 'マ', 'ミ', 'ム', 'メ', 'モ', 'ヤ', 'ユ', 'ヨ', 'ラ', 'リ', 'ル', 'レ', 'ロ', 'ワ', 'ヰ', 'ヱ', 'ヲ', 'ン', 'ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ', 'ㅏ', 'ㅑ', 'ㅓ', 'ㅕ', 'ㅗ', 'ㅛ', 'ㅜ', 'ㅠ', 'ㅡ', 'ㅣ', 'À', 'Â', 'Ä', 'Ç', 'É', 'È', 'Ê', 'Ë', 'Î', 'Ï', 'Ô', 'Œ', 'Ù', 'Û', 'ÜŸ,', 'أ', 'ت', 'ث', 'ج', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ك', 'ل', 'م', 'ن', 'هـ', 'و', 'ي', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'α', 'β', 'γ', 'δ', 'ε', 'ζ', 'η', 'θ', 'ι', 'κ', 'λ', 'μ', 'ν', 'ξ', 'ο', 'π', 'ρ', 'σ', 'ς', 'τ', 'υ', 'φ', 'χ', 'ψ', 'ω', '£', '€', '¥', '₹', '¢', '₩', '₪', '₫', '฿', '₱', '₴', '₦', '₽', '₡', '₭', '₲', '₵', '₺', '₿', '±', '÷', '×', '∫', '∑', '√', '∞', '∂', '≈', '≠', '≤', '≥', '∝', '∈', '∉', '∅', '∇', '∩', '∪', '∧', '∨', '∀', '∃', '∴', '∠', '∞', '∝', '⊥', '∥', '∥', '⊕', '⊗', '⊥', '∈', '∉', '∀', '∃', '∴', '≡', '≅', '∆', '一', '丨', '丿', '乙', '亅', '二', '十', '口', '日', '月', '火', '木', '水', '田', '土', '金', '人', '目', '手', '竹'];

function getRandomLetters(letters, letterCount) {
    // Shuffle the array using Fisher-Yates algorithm
    for (let i = letters.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [letters[i], letters[j]] = [letters[j], letters[i]];
    }

    // Return the first `letterCount` items from shuffled array
    return letters.slice(0, letterCount);
}
const randomLetters = getRandomLetters(letters, letterCount);

function generateRandomTextElements(letters, svgWidth, svgHeight) {
    function getRandomX() {
        // Randomly decide if x should be 0 or 100
        const x = Math.random() < 0.5 ? -5 : 100;
        // Generate a random y value between 0 and 100
        const y = Math.random() * 100;
        return { x, y };
    }
    function getRandomY() {
        const x = Math.random() * 100;
        const y = 0;
        return {x, y};
    }
    function randomizeFunction() {
        // Randomly decide which function to run
        const randomChoice = Math.random() < 0.6;
        if (randomChoice) {
            return getRandomX();
        } else {
            return getRandomY();
        }
    }

    const rotateValues = [-30, -25, -20, -15, -10, -5,  0, 5, 10, 15, 20, 25, 30];
    const fontSizeValues = [24, 28, 32, 36, 48, 54, 60];
    const durValues = ["2s", "3s", "4s", "5s","6s","7s","8s"];
    const beginValues = ["0.5s", "1s", "1.5s", "2s", "2.5s", "3s", "3.5s", "4s"];

    return letters.map((letter, index) => {
        const {x, y} = randomizeFunction();
        const rotate = rotateValues[Math.floor(Math.random() * rotateValues.length)];
        const fontSize = fontSizeValues[Math.floor(Math.random() * fontSizeValues.length)];
        const dur = durValues[Math.floor(Math.random() * durValues.length)];
        const begin = beginValues[Math.floor(Math.random() * beginValues.length)];
        const translateX = (svgWidth / 2) - (x / 100) * svgWidth;
        const translateY = (svgHeight / 2) - (y / 100) * svgHeight;
        const translateTo = `${translateX},${translateY}`;

        return (
            <text key={index} x={`${x}%`} y={`${y}%`} fill="white" visibility="visible" rotate={rotate}>
                {letter}
                <animate attributeName="opacity" values="1;0" begin={begin} dur={dur} repeatCount="indefinite" />
                <animate attributeName="font-size" values={`${fontSize};0`} begin={begin} dur={dur} repeatCount="indefinite" />
                <animateTransform attributeName="transform" attributeType="XML" type="translate" from="0,0" to={translateTo} begin={begin} dur={dur} repeatCount="indefinite" />
            </text>
        );
    });
}



const MainBackground = () => {
    const svgRef = useRef(null);
    const [svgDimensions, setSvgDimensions] = useState([]);

    useEffect(() => {
        if (svgRef.current) {
            const { width, height } = svgRef.current.getBoundingClientRect();
            setSvgDimensions(generateRandomTextElements(randomLetters, width, height));
        }
    }, []);


        return (
            <svg ref={svgRef}  width="100%" height="100%" viewBox="0 0 390 548" fill="none" xmlns="http://www.w3.org/2000/svg">
                {svgDimensions}

                <path d="M390 547.5L206 287.5" stroke="url(#paint0_linear_0_1)"/>
                <path d="M0 547.5L183 287.5" stroke="url(#paint1_linear_0_1)"/>
                <path
                    d="M40.612 442.454C-14.5184 397.094 -49.1725 327.915 -47.8298 250.988C-45.4941 117.175 64.8761 10.5917 198.689 12.9274C332.502 15.2631 439.085 125.633 436.749 259.446C435.521 329.827 404.406 392.675 355.712 436.104"
                    stroke="url(#paint2_linear_0_1)" strokeWidth="2" strokeLinecap="round"/>
                <path
                    d="M82.6866 393.724C42.5435 360.531 17.3127 309.903 18.2955 253.603C20.0049 155.671 100.383 77.6601 197.826 79.3609C295.268 81.0618 372.875 161.831 371.166 259.763C370.267 311.272 347.605 357.27 312.144 389.057"
                    stroke="url(#paint3_linear_0_1)" strokeWidth="1.7" strokeLinecap="round"/>
                <path
                    d="M104.346 362.726C71.9307 336.112 51.5542 295.524 52.342 250.391C53.7123 171.883 118.603 109.354 197.278 110.727C275.954 112.1 338.623 176.856 337.252 255.364C336.531 296.657 318.239 333.529 289.61 359.008"
                    stroke="url(#paint4_linear_0_1)" strokeWidth="1.4" strokeLinecap="round"/>
                <path
                    d="M122.757 342.127C96.8823 320.998 80.6154 288.777 81.2408 252.95C82.3286 190.629 134.117 140.997 196.913 142.093C259.709 143.189 309.734 194.599 308.646 256.919C308.074 289.698 293.476 318.966 270.628 339.189"
                    stroke="url(#paint5_linear_0_1)" strokeWidth="1.1" strokeLinecap="round"/>
                <path
                    d="M137.829 326.699C117.307 309.961 104.406 284.435 104.901 256.052C105.763 206.681 146.836 167.363 196.639 168.232C246.443 169.102 286.119 209.829 285.257 259.2C284.804 285.167 273.227 308.354 255.106 324.375"
                    stroke="url(#paint6_linear_0_1)" strokeWidth="0.7" strokeLinecap="round"/>
                <path
                    d="M147.801 313.8C130.851 299.809 120.198 278.47 120.612 254.74C121.332 213.463 155.269 180.583 196.411 181.301C237.554 182.019 270.322 216.063 269.602 257.341C269.223 279.051 259.655 298.438 244.683 311.836"
                    stroke="url(#paint7_linear_0_1)" strokeWidth="0.4" strokeLinecap="round"/>
                <path
                    d="M159.443 298.316C146.659 287.62 138.626 271.304 138.942 253.157C139.493 221.592 165.101 196.443 196.138 196.985C227.175 197.527 251.889 223.554 251.338 255.119C251.048 271.721 243.827 286.549 232.53 296.796"
                    stroke="url(#paint8_linear_0_1)" strokeWidth="0.2" strokeLinecap="round"/>
                <defs>
                    <linearGradient id="paint0_linear_0_1" x1="206" y1="287.5" x2="353.148" y2="676.395"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0"/>
                        <stop offset="1" stopColor="white"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_0_1" x1="183" y1="287.5" x2="35.2503" y2="675.862"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" stopOpacity="0"/>
                        <stop offset="1" stopColor="white"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_0_1" x1="198.689" y1="12.9274" x2="191.146" y2="445.081"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_0_1" x1="197.826" y1="79.3609" x2="192.305" y2="395.637"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear_0_1" x1="197.278" y1="110.727" x2="192.853" y2="364.271"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint5_linear_0_1" x1="196.913" y1="142.093" x2="193.4" y2="343.36"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint6_linear_0_1" x1="196.639" y1="168.232" x2="193.856" y2="327.677"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint7_linear_0_1" x1="196.411" y1="181.301" x2="194.085" y2="314.608"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint8_linear_0_1" x1="196.138" y1="196.985" x2="194.358" y2="298.925"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1"/>
                    </linearGradient>
                </defs>
            </svg>
        );
};

export default MainBackground;
