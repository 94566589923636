import React from 'react';
import Slider from "./Slider";
import styled from "styled-components";
import CodeBlock from "./CodeBlock";

const Body: React.FC<{onFocusHandler: (focused: boolean) => void}> = ({onFocusHandler}) => {

    return (
        <Container>
            <Slider/>
            <CodeBlock onFocusHandler={onFocusHandler}/>
        </Container>
    );
};

export default Body;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`;
