import React from 'react';

const SocialXIcon = () => {
    return (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.3263 0.903931H20.6998L13.3297 9.32742L22 20.7899H15.2112L9.89404 13.838L3.80995 20.7899H0.434432L8.31743 11.78L0 0.903931H6.96111L11.7674 7.25826L17.3263 0.903931ZM16.1423 18.7707H18.0116L5.94539 2.81706H3.93946L16.1423 18.7707Z"
                fill="white"/>
        </svg>

    );
};

export default SocialXIcon;
