import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/global.css'
import {store} from "./store/store";
import {Provider} from "react-redux";
import {RouterProvider} from "react-router-dom";
import router from "./router/router";
import WebApp from '@twa-dev/sdk'

WebApp.ready();
WebApp.expand();
WebApp.disableVerticalSwipes();
WebApp.setHeaderColor('#000');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <RouterProvider router={router} />
      </Provider>
  </React.StrictMode>
);
