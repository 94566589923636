import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {DateToUTC, PadZero} from "../../utils/date-utils";

interface ScoreBlockProps {
    points: number;
    day: number;
    season: number;
}

const ScoreBlock: React.FC<ScoreBlockProps> = ({points, day, season}) => {

    const [time, setTime] = useState<Date>(DateToUTC(new Date()));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(DateToUTC(new Date()));
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const hours = PadZero(time.getHours());
    const minutes = PadZero(time.getMinutes());
    const seconds = PadZero(time.getSeconds());

    return (
        <div>
            <Scores>{points}</Scores>
            <Season>S{season}:{day}D:{`${hours}:${minutes}:${seconds}`}</Season>
        </div>
    );
};

export default ScoreBlock;

const Scores = styled.h2`
    margin: 0 0 12px;
    font-size: 48px;
    letter-spacing: 1px;
    font-weight: 500;
`;
const Season = styled.p`
    margin: 0;
    font-size: 24px;
    opacity: .7;
    letter-spacing: 1px;
    font-weight: 500;
`;
