import React, {useEffect} from 'react';
import styled from "styled-components";
import './animation.css'
import {animation} from "./animation";

const Header = () => {

    useEffect(() => {
        animation();
    }, []);

    return (
        <Container>
            <H2>Codes</H2>
            <AnimationWrapper>
                <div id="spiral" className="spiral"></div>
                <div id="spiral2" className="spiral"></div>
            </AnimationWrapper>
        </Container>
    );
};

export default Header;

const Container = styled.div`
    height: 100%;
    padding: 0;
    overflow: hidden;
`;
const H2 = styled.h2`
    padding: 0 24px;
    font-size: 32px;
    font-weight: 700;
    margin: 24px 0 24px;
    opacity: .7;
`;
const AnimationWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 88px;
`;
