import React from 'react';

const RectangleIcon = () => {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="8" width="10.4" height="10.4" transform="rotate(-45 0 8)" fill="white"/>
        </svg>
    );
};

export default RectangleIcon;
