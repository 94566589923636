import React, {useState} from 'react';
import InviteModal from "../ReferralScreen/InviteModal";
import ActionButton from "./ActionButton";

const InviteButton = () => {
    const [showModal, setShowModal] = useState(false);

    const clickHandler = () => {
        setShowModal(prevState => !prevState);
    };

    return (
        <>
            <ActionButton type="active" width="100%" height="48px" clickHandler={clickHandler}>
                + invite witnesses
            </ActionButton>

            {showModal && <InviteModal clickHandler={clickHandler}/>}
        </>
    );
};

export default InviteButton;
